import { useMemo, useState, useEffect } from 'react';
import { useSearchParams, usePathname } from 'next/navigation';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Style
import LanguageSwitcherStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';

const LanguageSwitcher = ({ selectedLang, languages }) => {
	//! State
	const [isDropDownActive, setIsDropdownActive] = useState(false);

	//! Next Navigation
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const storeLanguages = useMemo(() => {
		const route = pathname.replace(`/${selectedLang}`, '');
		const queryString = searchParams.toString();

		return [...Object.keys(languages)].map((lang) => {
			return selectedLang !== lang ? (
				<div
					key={lang}
					className='lang-item'>
					<Link
						locale={lang}
						as={`/${lang}${route}${queryString ? `?${queryString}` : ''}`}
						href={`/[lang]${route}${queryString ? `?${queryString}` : ''}`}>
						<Text
							className={'p p3 font-montserrat-medium blue-1000-color'}
							text={lang}
						/>
					</Link>
				</div>
			) : null;
		});
	}, [selectedLang, pathname, searchParams]);

	//! Check className
	const checkClass = (el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`);

	//! Detect click outside
	const handleClickOutside = (e) => {
		if (checkClass(e.target, 'selected-lang-wrap') && !isDropDownActive) {
			setIsDropdownActive(true);
		} else if (!checkClass(e.target, 'dropdown') && isDropDownActive) {
			setIsDropdownActive(false);
		}
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropDownActive]);

	return (
		<LanguageSwitcherStyle>
			<div className='selected-lang-wrap'>
				<Text
					className={'uppercase p p3 font-montserrat-medium lang-text'}
					text={selectedLang}
				/>

				<Icon
					name={'dropdown'}
					className={`language-dropdown-icon p p5 ${isDropDownActive ? 'active' : ''}`}
				/>

				{isDropDownActive ? <div className={`dropdown`}>{storeLanguages}</div> : null}
			</div>
		</LanguageSwitcherStyle>
	);
};

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
